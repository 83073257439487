/** @jsx jsx */
import { useRef } from 'react';
import { Container, Flex, Heading, Text, jsx } from 'theme-ui';
import { injectIntl } from 'gatsby-plugin-intl';
import _ from 'lodash';
import Spacer from '~/components/Generic/Layout/Spacer';
import ImageMarquee from '~/components/Generic/Marquee/Image';
import useOnScreen from '~/hooks/components/use-on-screen';
import ProductList from '../List';
import { configureFatMedia } from '~/utils/fat-media';
import { HomeReviews } from '~/components/Pages/Splash/Sections/HomeReviews';
import { ClimateMarquee } from '~/components/Generic/ClimateMarquee';

const ProductListPage = ({ page, catalog }) => {
  const { sections } = page;
  const ref = useRef();
  const onScreen = useOnScreen(ref);

  const reviewSection = page.sections.find((section) => section?.slug?.includes('review'));

  configureFatMedia({ pageType: 'CATALOG' });

  const imageNodes = _.map(sections[1].marqueeImages, (marqueeImage) => ({
    description: marqueeImage.altText,
    file: marqueeImage.image?.file,
    gatsbyImageData: marqueeImage.image?.gatsbyImageData,
    link: marqueeImage.link,
    label: marqueeImage.label,
  }));

  const { products } = catalog.collections[0];

  return (
    <Container
      className={`fade-in-up ${onScreen ? 'visible' : ''}`}
      ref={ref}
      variant="fullwidth"
      sx={{
        position: 'relative',
      }}
    >
      <Flex sx={{ my: ['4rem', '5rem', '5rem'], flexDirection: 'column', alignItems: 'center' }}>
        <Heading variant="leadHeading" sx={{ fontSize: ['3rem', '4.5rem', '4.8rem'], mb: '1rem' }}>
          SHOP ALL
        </Heading>
        <Text
          sx={{
            textAlign: 'center',
            fontSize: ['1.5rem', '2rem', '3.6rem'],
            fontStyle: 'italic',
            fontWeight: '800',
            lineHeight: 'normal',
          }}
        >
          PERIOD CARE FOR EVERYONE WHO MENSTRUATES
        </Text>
      </Flex>
      <Container
        variant="fullwidth"
        sx={{
          width: '95%',
          maxWidth: '120rem',
          mt: '2rem',
        }}
      >
        <ProductList products={products} />
      </Container>
      <Spacer height={['50px', '80px', '100px']} />
      <ClimateMarquee showOnDesktop />
      <Spacer height={['50px', '80px', '100px']} />
      <HomeReviews section={reviewSection} />
    </Container>
  );
};

export default injectIntl(ProductListPage);
