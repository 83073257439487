/** @jsx jsx */
import { jsx, Heading } from 'theme-ui';
import { graphql } from 'gatsby';
import Layout from '~/components/Layout';
import Metadata from '~/components/Metadata';
import ProductListPage from '~/components/Product/ListPage';

const Shop = ({ data }) => {
  const { catalog, page } = data;

  return (
    <Layout hideWidget>
      <Metadata title="Shop" description="Redefining Periods" />
      <ProductListPage catalog={catalog} page={page} />
    </Layout>
  );
};

export default Shop;

export const query = graphql`
  query Shop {
    catalog: contentfulCatalog(slug: { eq: "shop-all" }) {
      collections {
        ...CollectionDetailsFragment
      }
    }
    page: contentfulPage(slug: { eq: "shop" }) {
      ...GenericPageFragment
    }
  }
`;
